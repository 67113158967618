import { Base64 } from 'js-base64';

export const decodeResponse = (value) => {
    let data = value;
    if (isJSON(data)) {
        return data;
    }
		if (value.length >= 20) {
				data = value.slice(20, -12);
				data = JSON.parse(Base64.decode(data))
    }
    return data
};

function isJSON(str) {
    if (typeof str == 'string') {
        try {
            JSON.parse(str);
            return true;
        } catch(e) {
            return false;
        }
    }
}
