import axios from 'axios';
import {Toast} from 'vant';
import router from "../router";
import {baseUrl, secret} from "../config";
import {decodeResponse} from "../utils/decode";
import md5 from 'js-md5'


const service = axios.create({
    baseURL: baseUrl,
    timeout: 10000
});

service.interceptors.request.use(
    config => {
        if (localStorage.getItem("xd_partner_user_token")) {
            config.headers['Token'] = localStorage.getItem("xd_partner_user_token")
        }
        let t = Date.now();
        config.headers['Sec'] = md5(t + secret);
        config.headers['Time'] = t;
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
response => {
    const res = response.data;
    if (response.status !== 200) {
        return Promise.reject(res || 'error')
    } else {
        // 登录超时,重新登录
        if (res.code === -1) {
            localStorage.clear();
            Toast.fail(res.message);
            router.push("/login")
        } else if (res.code !== 200) {
            Toast.fail(res.message);
            return Promise.reject();
        } else {
            return Promise.resolve(decodeResponse(res.data))
        }
    }
},
    error => {
        return Promise.reject(error)
    }
);

export default service;
